import { ICompanyCreateAdvertisementVariables } from "./_company_createAdvertisement"
import { OzgelecekFieldEnum, PlaceOfWorkEnums, PositionLevelEnums, ILanguage, ProductTypeEnums, WorkTypeEnums, ExtraProductEnums, LanguageEnums, HigherEducationDegreeEnum } from "types"



export interface ICompanyUpdateAdvertisementResponse {
    company_updateAdvertisement: {
        success: boolean
    }
}

export interface ICompanyUpdateAdvertisementVariables extends ICompanyCreateAdvertisementVariables {
    productType?: ProductTypeEnums | string,
    quota?: number | null,
    quotaVisibleToApplicant?: boolean | null,
    startDate?: string,
    title?: string | null,
    // extraProducts?: Array<string> | ExtraProductEnums[] | string[] | null,
    extraProducts?: any,
    status?: string | null,
    advertisementId?: any,
    field?: any,
    department?: string | null,
    language?: LanguageEnums | string | null,
    place?: PlaceOfWorkEnums | string | null,
    description?: string | null,
    workType?: WorkTypeEnums | string | null,
    positionLevel?: PositionLevelEnums | string | null,
    city?: string | null,
    district?: string | null,
    requiredSkills?: Array<string> | string[] | null,
    requiredLanguages?: Array<ILanguage>,
    requiredEducationLevel?: Array<HigherEducationDegreeEnum> | null,
    requiredDriverLicense?: string | null,
    requiredAgeInterval?: any,
    militaryServiceRequired?: boolean | null,
    companyInfoVisible?: boolean | null,
    replyNotification?: string | null,
    customReplyNotification?: {
        content?: string | null,
        subject?: string | null,
    },
    images?: Array<string> | null
}


export const company_updateAdvertisement = (variables: ICompanyUpdateAdvertisementVariables) => {

    const data = JSON.stringify({

        query: `mutation Company_updateAdvertisement($advertisementId: ID!, $advertisementInput: UpdateAdvertisementInput!) {
            company_updateAdvertisement(advertisementId: $advertisementId, advertisementInput: $advertisementInput) {
              success
            }
          }`,
        variables: {
            "advertisementId": variables.advertisementId ?? "",
            "advertisementInput": {
                "productType": variables?.productType ?? "",
                "quota": variables?.quota ?? 0,
                "quotaVisibleToApplicant": variables?.quotaVisibleToApplicant ?? null,
                "startDate": variables?.startDate ?? "",
                "extraProducts": variables?.extraProducts ?? [],
                "status": variables.status ?? "",
                "city": variables.city ?? "",
                "district": variables.district ?? "",
                "title": variables.title ?? "",
                "field": variables.field ?? null,
                "department": variables.department ?? "",
                "language": variables.language ?? null,
                "place": variables.place ?? null,
                "description": variables.description ?? "",
                "workType": variables.workType ?? null,
                "positionLevel": variables.positionLevel ?? null,
                "requiredSkills": variables?.requiredSkills ?? null,
                "requiredLanguages": variables?.requiredLanguages ?? [],
                "requiredEducationLevel": variables?.requiredEducationLevel ?? null,
                "requiredDriverLicense": variables?.requiredDriverLicense ?? null,
                "requiredAgeInterval": variables?.requiredAgeInterval ?? null,
                "militaryServiceRequired": variables?.militaryServiceRequired ?? null,
                "companyInfoVisible": variables?.companyInfoVisible ?? false,
                "replyNotification": variables?.replyNotification ?? null,
                "customReplyNotification": variables?.customReplyNotification ?? null,
                "images": variables?.images ?? []
            }
        }


        // "advertisementId": variables.advertisementId ?? null,
        // // "extraProduct": variables?.onboardingFirst?.extraProduct ?? null,
        // // "productType": variables?.onboardingFirst?.productType ?? null,
        // "quota": variables?.quota ?? null,
        // "quotaVisibleToApplicant": variables?.quotaVisibleToApplicant ?? null,
        // "startDate": variables?.startDate ?? null,
        // "title": variables?.onboardingFirst?.title ?? null,
        // "advertisementInput": {
        //     "companyInfoVisible": variables?.companyInfoVisible ?? false,
        //     "customReplyNotification": {
        //         "content": variables?.customReplyNotification?.content ?? null,
        //         "subject": variables?.customReplyNotification?.subject ?? null,
        //     },
        //     "images": variables?.images ?? null,
        //     "onboardingFirst": {

        //     },
        //     "onboardingSecond": {
        //         "city": variables?.onboardingSecond?.city ?? null,
        //         "department": variables?.onboardingSecond?.department ?? null,
        //         "description": variables?.onboardingSecond?.description ?? null,
        //         "district": variables?.onboardingSecond?.district ?? null,
        //         "field": variables?.onboardingSecond?.field ?? null,
        //         "language": variables?.onboardingSecond?.language ?? null,
        //         "place": variables?.onboardingSecond?.place ?? null,
        //         "positionLevel": variables?.onboardingSecond?.positionLevel ?? null,
        //         "workType": variables?.onboardingSecond?.workType ?? null,
        //     },
        //     "onboardingThird": {

        //     },
        //     "replyNotification": variables?.replyNotification ?? null
        // }


    })

    return data

}