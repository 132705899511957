import axios from 'axios';
import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Link, Stack, IconButton, Typography, InputAdornment, Box, InputLabel, Dialog } from '@mui/material';

import { routes } from 'routes';
import { useBoolean } from 'minimal/hooks/use-boolean';
import { Iconify, RHFTextField, FormProvider, RHFCheckbox } from 'components';


const RegisterFormSchema = Yup.object().shape({
  email: Yup.string().required('Gerekli').email('Geçerli bir email adresi giriniz'),
  password: Yup.string()
    .min(8, "En az 8 karakter içermeli")
    .matches(/^(?=.*[a-z])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/^(?=.*[A-Z])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/^(?=.*[0-9])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/^(?=.*[!@#%&?.,+%-_])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/(?=.*[!'^+%.,/()&=?\-_*])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .required('Gerekli'),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), "null"], 'Şifreler eşleşmeli'),
  conditions: Yup.boolean().required('Gerekli').oneOf([true], ''),
});



export const RegisterContainer = () => {

  const password = useBoolean();
  const navigate = useNavigate()
  const [role, setRole] = useState("")
  const [openPrivacy, setOpenPrivacy] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)

  const methods = useForm({
    resolver: yupResolver(RegisterFormSchema),
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      conditions: false,
    },
    mode: 'all',
  });


  const { handleSubmit, formState: { isSubmitting, isValid, errors } } = methods;


  const onSubmit = handleSubmit(async (data) => {

    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/register`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ ...data, role })
      };

      const response = await axios.request(config)

      console.log(response?.data)

      if (response?.data?.isSuccess) {
        enqueueSnackbar("Kayıt başarılı! Giriş yapmak için e-mail onaylama gerekiyor!")
        navigate(routes.auth.registerSuccessfull)
      } else {
        enqueueSnackbar("Başka bir email deneyiniz ya da daha sonra tekrar deneyiniz!", {
          variant: 'error'
        })
      }

    } catch (error: any) {

      console.error(error);
      if (error?.response?.data?.message) {
        error?.response?.data?.errors?.forEach((item: any) => {
          if (item.msg === "Email already in use") {
            enqueueSnackbar("Bu email kullanılmaktadır!", {
              variant: 'error'
            })
          } else {
            enqueueSnackbar("Birşeyler yanlış gitti!", {
              variant: 'error'
            })
          }
        })
      } else {
        enqueueSnackbar("Birşeyler yanlış gitti!", {
          variant: 'error'
        })
      }


    }



  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }} direction={"row"} alignItems={"center"}>
      {role && <Stack direction="row" justifyContent={"center"} onClick={() => setRole("")} >
        <Iconify icon="uiw:left" width={16} sx={{ mt: 0.4 }} />

      </Stack>}
      <Typography variant="h4">Yeni bir hesap oluştur</Typography>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2} flexDirection={"column"}>

      <Box>
        <InputLabel>Email</InputLabel>
        <RHFTextField name="email" placeholder="Emailinizi giriniz" />
      </Box>

      <Box>
        <InputLabel>Şifre</InputLabel>
        <RHFTextField
          name="password"
          placeholder="Şifrenizi giriniz"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box>
        <InputLabel>Şifre Onayla</InputLabel>
        <RHFTextField
          name="passwordConfirm"
          placeholder="Şifrenizi tekrar giriniz"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <RHFCheckbox
          name='conditions'
          // label="Kullanım Koşulları ve Gizlilik Politikası'nı Kabul ediyorum."
          label={
            <Typography
              htmlFor="register_conditions"
              component="label"
              sx={{ color: !!errors.conditions ? '#961650 !important' : 'text.secondary', typography: 'caption', textAlign: 'center' }}
            >
              <Link underline="always" color={!!errors.conditions ? '#961650 !important' : 'text.secondary'} onClick={() => setOpenTerms(true)}>
                Kullanım Koşulları
              </Link>
              {' ve '}
              <Link underline="always" color={!!errors.conditions ? '#961650 !important' : 'text.secondary'} onClick={() => setOpenPrivacy(true)}>
                Gizlilik Politikası'nı
              </Link>
              {' Kabul ediyorum.'}
              .
            </Typography>
          }
        />

        {/* <Typography
          htmlFor="register_conditions"
          component="label"
          sx={{ color: 'text.secondary', typography: 'caption', textAlign: 'center' }}
        >
          <Link underline="always" color="text.primary">
            Kullanım Koşulları
          </Link>
          {' ve '}
          <Link underline="always" color="text.primary">
            Gizlilik Politikası'nı
          </Link>
          {'Kabul ediyorum. '}
          .
        </Typography> */}
      </Box>

      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        disabled={!isValid}
        loading={isSubmitting}
      >
        Kayıt Ol
      </LoadingButton>




      <Stack direction="row" spacing={0.5} display={"flex"} justifyContent={"center"} sx={{ alignItems: 'center' }}>
        <Typography variant="body2" > Zaten hesabın var mı? </Typography>

        <Link to={routes.auth.login} component={RouterLink} variant="subtitle2">
          Giriş Yap
        </Link>
      </Stack>

      <Dialog open={openPrivacy} onClose={() => setOpenPrivacy(false)}>
        <Stack spacing={2} sx={{ p: 3, width: "100%" }}>
          <Typography variant="h5"> Gizlilik Politikası</Typography>
          <Typography variant="body1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </Typography>
        </Stack>
      </Dialog>
      <Dialog open={openTerms} onClose={() => setOpenTerms(false)}>
        <Stack spacing={2} sx={{ p: 3, width: "100%" }}>
          <Typography variant="h5">Kullanım Koşulları</Typography>
          <Typography variant="body1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </Typography>
        </Stack>
      </Dialog>
    </Stack>
  );

  const renderRole = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >

      {
        [{ value: "candidate", name: "ADAY" }, { value: "company", name: "FİRMA" }].map(item => {
          return (
            <Box
              key={item?.value}
              onClick={() => setRole(item?.value)}
              sx={{
                // borderRadius: "18px",
                border: "3px solid #ddd",
                p: 3,
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                transitionDuration: "300ms",
                cursor: "pointer",

                '&:hover': {
                  border: "3px solid #ddd",
                  boxShadow: '1px 1px 10px 0px #eee',
                  color: "#666",
                  cursor: "pointer",
                },

              }}
            >
              {item?.value === "candidate" ? <Iconify icon="icon-park-outline:user-business" width={30} sx={{ mr: 2 }} /> : <Iconify icon="ion:business" width={25} sx={{ mr: 1.5 }} />}
              <Typography variant="h3">{item?.name}</Typography>
            </Box>
          )
        })
      }

      <Stack direction="row" spacing={0.5} display={"flex"} justifyContent={"center"} sx={{ alignItems: 'center' }}>
        <Typography variant="body2"> Zaten hesabın var mı? </Typography>

        <Link to={routes.auth.login} component={RouterLink} variant="subtitle2">
          Giriş Yap
        </Link>
      </Stack>
    </Box>
  )


  return (
    <Box sx={{
      paddingTop: 20,
      height: "80vh",
      px: { xs: 0, sm: 3, lg: 10 },
      justifyContent: "center",
    }}>
      <FormProvider methods={methods} onSubmit={onSubmit} >
        {renderHead}
        {role ? renderForm : renderRole}
      </FormProvider>
    </Box >
  );
}