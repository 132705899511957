
import { Box, Stack, SxProps, Typography, keyframes } from '@mui/material';

import { AuthLayoutBackgroundImage, LoginComment, Logo, LoginGirl } from 'assets';
import { Grid } from '@mui/material';

interface IAuthLayoutProps {
  children: any,
  image?: string,
  title?: string
}


export const AuthLayout = ({ children, image, title }: IAuthLayoutProps) => {




  return (
    <Grid container spacing={1}>

      {/* ****************** left section ******************* */}
      <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'initial' } }}>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          gap={3}
          sx={{
            height: '100vh',
            bgcolor: '#f8f8fd',
            backgroundImage: `url(${AuthLayoutBackgroundImage})`
          }}
        >
          <img src={Logo} alt="logo"
            style={{
              zIndex: 10,
              paddingTop: 50,
              paddingLeft: 50,
              alignSelf: 'flex-start',
              objectFit: 'contain',
              width: 250
            }} />


          <Box sx={{ position: 'relative' }}>

            <AnimatedCardOne />
            <AnimatedCardTwo />

            <Box
              component="img"
              alt="auth"
              src={image ?? LoginGirl}
              sx={{
                height: '70vh',
                objectFit: 'contain',
                position: 'relative',
                zIndex: 1,
              }}
            />
          </Box>

        </Stack>
      </Grid>

      {/* ****************** right section ******************* */}
      <Grid item xs={12} md={6} >
        <Stack
          p={10}
        >
          {children}
        </Stack>
      </Grid>

    </Grid>
  )
}


const myEffect = keyframes`
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(20);
  }
`;


const AnimatedCardOne = () => {

  return (
    <Box
      sx={{
        display: { xs: 'none', lg: 'initial' },
        p: 2,
        boxShadow: '1px 1px 5px 0px #ccc',
        backgroundColor: '#fff',
        position: 'absolute',
        top: -40,
        left: -40,
        width: '150px',
        animationName: `${myEffect}`,
        animationDuration: '3s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        animationTimingFunction: 'linear'
      }}
    >

      <Typography variant='h3'>
        100K+
      </Typography>

      <Typography variant='body1'>
        People got hired
      </Typography>

    </Box>
  )
}

const AnimatedCardTwo = () => {

  return (
    <Box
      sx={{
        display: { xs: 'none', lg: 'initial' },
        p: 2,
        boxShadow: '1px 1px 15px 0px #ccc',
        backgroundColor: '#fff',
        position: 'absolute',
        bottom: 30,
        right: -60,
        zIndex: 10,
        width: '250px',
        animationName: `${myEffect}`,
        animationDuration: '3.5s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        animationTimingFunction: 'linear',
      }}
    >

      <Box
        component={'img'}
        alt="auth"
        src={LoginComment}
        sx={{
          width: '80px',
          position: 'absolute',
          top: '-35px',
          border: '10px solid #fff',
          right: "20px",
          borderRadius: '100%',
          bgcolor: '#ccc',
        }}
      />

      <Box sx={{ mb: 2, }}>
        <Typography variant='body1' sx={{ color: '#666' }}>
          Adam Sandler
        </Typography>

        <Typography variant='body2' sx={{ color: '#999' }}>
          Lead Engineer at Canva
        </Typography>
      </Box>

      <Typography variant='subtitle2' sx={{ pl: 2, textAlign: 'justify' }}>
        “Great platform for the job seeker that searching for new career heights.”
      </Typography>

    </Box>
  )
}