import * as yup from "yup"
import { Formik } from 'formik'
import { Button, Card, Grid, Typography } from '@mui/material'

import { IParticipatedProjects } from 'types'
import { FormikDatePicker, FormikTextField } from 'components'




const validationSchema = yup.object().shape({
    name: yup.string().nullable()?.required("İsim gerekli!"),
    date: yup.date().nullable()?.required("Tarih gerekli!"),
    institution: yup.string().nullable()?.required("Kurum ismi gerekli!"),
    details: yup.string().nullable(),
})





interface IParticipatedProjectsFormProps {
    onSubmit: (e: IParticipatedProjects) => any,
    initialValues?: IParticipatedProjects
}
export const ParticipatedProjectForm = ({ onSubmit, initialValues }: IParticipatedProjectsFormProps) => {


    const defaultValues = initialValues ?? {
        name: "",
        date: null,
        institution: "",
        details: "",
        level: "beginner",
    }


    return (
        <Formik
            initialValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                onSubmit(values)
                resetForm()
            }}
        >
            {
                ({ handleSubmit }) => {
                    return (
                        <Card sx={{ width: '100%', p: 3 }}>
                            <Grid container spacing={3} >

                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Katıldığı Projeler
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikTextField
                                        name="name"
                                        label="Proje adı"
                                        upperCase
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikDatePicker
                                        name="date"
                                        label="Tarih"
                                    />
                                </Grid>


                                <Grid item xs={12} >
                                    <FormikTextField
                                        name="institution"
                                        label="Kurum"
                                        upperCase
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormikTextField
                                        multiline
                                        minRows={4}
                                        name="details"
                                        label="Detay"
                                        upperCase
                                    />
                                </Grid>


                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }} >
                                    <Button
                                        onClick={() => handleSubmit()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {initialValues ? "Güncelle" : "Ekle"}
                                    </Button>
                                </Grid>

                            </Grid>
                        </Card>
                    )
                }
            }
        </Formik>
    )
}
