import * as yup from "yup"
import { Formik } from 'formik'
import { useState } from "react"
import { Box, Button, Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material'

import { IReference } from 'types'
import { FormikTextField, MuiTransition, ResponsiveButton, FormikTextFieldPhone } from 'components'


const validationSchema = yup.object().shape({
    referenceInformation: yup.object().shape({
        email: yup.string().required("Email alanı zorunludur").email("Geçerli bir email adresi giriniz"),
        jobTitle: yup.string().nullable(),
        name: yup.string().required("Ad alanı zorunludur"),
        phoneNumber: yup.string().nullable(),
        relationWithCandidate: yup.string().nullable(),
        surname: yup.string().required("Soyad alanı zorunludur"),
    })
})


interface IReferenceFormProps {
    onSubmit?: (e: IReference) => any,
    defaultValues?: IReference | undefined | null,
    loading?: boolean
}


export const ReferenceForm = ({ onSubmit, defaultValues, loading }: IReferenceFormProps) => {

    const [createReferenceDialog, setCreateReferencesDialog] = useState(false)

    const initialValues = {
        "referenceInformation": {
            "email": defaultValues?.referenceInformation?.email ?? "",
            "jobTitle": defaultValues?.referenceInformation?.jobTitle ?? "",
            "name": defaultValues?.referenceInformation?.name ?? "",
            "phoneNumber": defaultValues?.referenceInformation?.phoneNumber ?? "",
            "relationWithCandidate": defaultValues?.referenceInformation?.relationWithCandidate ?? "",
            "surname": defaultValues?.referenceInformation?.surname ?? "",
        }
    }

    return (
        <>
            <ResponsiveButton
                loading={loading}
                title="Referans Ekle"
                onClick={() => setCreateReferencesDialog(true)}
            />

            <Dialog
                open={createReferenceDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setCreateReferencesDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogContent>
                    <Box sx={{ py: 3 }}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                onSubmit && onSubmit(values)
                                setCreateReferencesDialog(false)
                                resetForm()
                                // console.log({ values });
                            }}
                        >
                            {
                                ({ handleSubmit }) => {
                                    return (

                                        <Grid container spacing={3} >

                                            <Grid item xs={12}>
                                                <Typography variant="h5">
                                                    Referans Ekle
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormikTextField
                                                    name="referenceInformation.name"
                                                    label="Adı*"
                                                    upperCase
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormikTextField
                                                    name="referenceInformation.surname"
                                                    label="Soyadı*"
                                                    upperCase
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormikTextField
                                                    name="referenceInformation.email"
                                                    label="Email*"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormikTextFieldPhone
                                                    name="referenceInformation.phoneNumber"
                                                    label="Telefon Numarası"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormikTextField
                                                    name="referenceInformation.jobTitle"
                                                    label="İş Ünvanı"
                                                    upperCase
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormikTextField
                                                    name="referenceInformation.relationWithCandidate"
                                                    label="Aday ile İlişkisi"
                                                    upperCase
                                                />
                                            </Grid>




                                            <Grid item xs={12} >
                                                <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
                                                    <Button
                                                        onClick={() => setCreateReferencesDialog(false)}
                                                        size="large"
                                                        color="primary"
                                                        variant="outlined"
                                                    >
                                                        Kapat
                                                    </Button>


                                                    <Button
                                                        onClick={() => handleSubmit()}
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Ekle
                                                    </Button>
                                                </Stack>
                                            </Grid>


                                        </Grid>
                                    )
                                }
                            }
                        </Formik>
                    </Box>
                </DialogContent>

            </Dialog>
        </>
    )
}