
import { Box, Chip, Dialog, DialogContent, Divider, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'


import { ICandidateOzgeleceksItem, useGetCandidateInformationQuery, useLazyGetCandidateInformationQuery } from 'context';
import { DriverLicenseStatusEnum, LanguageLevelEnum, OzgelecekFieldEnum, SexEnum, getNameOfEnum } from 'types';
import { enumFunctions, upperCasedString } from 'utils';
import { Logo, LogoCrop, User } from 'assets';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { LoginGirl } from 'assets';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useState } from 'react';
import moment from 'moment';
import { Download } from '@mui/icons-material';
import { PDFViewer } from '@react-pdf/renderer';
import HigherEducationDegree from 'enums/user/user/education/HigherEducationDegree';

Font.register({
    family: "RobotoLight",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
});

Font.register({
    family: "RobotoBold",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
});


interface IOzgelecekCardProps {
    ozgelecek: ICandidateOzgeleceksItem,
    onDelete: () => void,
    onUpdate: () => void,
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fefefe',
        padding: 20,
    },
    container: {
        width: "100%",
        height: "900px",
        margin: 10,
        padding: 10,
        position: 'relative',
        backgroundColor: 'red'
    },
    title: {
        fontSize: 24,
        fontFamily: 'RobotoBold',
        textAlign: 'center'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'RobotoBold',
        marginTop: 20
    },
    text: {
        fontSize: 16,
        fontWeight: 'bold',

        fontFamily: 'Roboto'
    },
    text2: {
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: 'Roboto'
    }
});

{/* <Dialog open={!!candidateDetail} onClose={() => {
    setCandidateDetail(null)
    setCandidateId(null)
}}>
    <DialogTitle id="simple-dialog-title">Aday Detayı</DialogTitle>
    <DialogContent sx={{ width: { xs: '100%', sm: "600px" } }}>
        <Stack spacing={2}>
            <Stack spacing={2} direction={"row"} alignItems={"center"} bgcolor={"secondary.lighter"} p={2} borderRadius={2}>
                <Avatar alt={candidateDetail?._id} src={candidateDetail?.personalInformation?.profileImageUrl} sx={{ width: 80, height: 80 }} />
                <Stack spacing={0.5}>
                    <Typography variant={"h6"}>{candidateDetail?.personalInformation?.name} {candidateDetail?.personalInformation?.surname}</Typography>
                    <Typography fontSize={"small"}>📞 <a href={`tel:${candidateDetail?.personalInformation?.phoneNumber}`} style={{ color: 'black', fontWeight: "bold" }}>
                        {candidateDetail?.personalInformation?.phoneNumber}</a> </Typography>
                    <Typography fontSize={"small"}>📧 <a href={`mailto:${candidateDetail?.contact?.email}`} style={{ color: 'black', fontWeight: "bold" }}>
                        {candidateDetail?.contact?.email}</a> </Typography>
                </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent="space-between" alignItems="flex-start">
                <Stack spacing={1} width={{ xs: '100%', sm: "300px" }} >
                    <Typography fontWeight={"bold"}>Kişisel Bilgiler</Typography> 
                    <Typography fontSize={"small"}><strong>Doğum Tarihi: </strong> {moment(candidateDetail?.personalInformation?.birthDate).format("DD MMMM YYYY")}</Typography>
                    <Typography fontSize={"small"}><strong>Cinsiyet: </strong> {getNameOfEnum(SexEnum, candidateDetail?.personalInformation?.sex)}</Typography>
                    <Typography fontSize={"small"}><strong>Cinsel Tercih:</strong> {getNameOfEnum(GenderEnum, candidateDetail?.personalInformation?.gender)} {getNameOfEnum(GenderEnum, candidateDetail?.personalInformation?.genderDetail)}  </Typography>
                    <Typography fontSize={"small"}><strong>Uyruk: </strong> {candidateDetail?.personalInformation?.nationality}</Typography>
                    <Typography fontSize={"small"}><strong>Farklı Vatandaşlık: </strong> {candidateDetail?.personalInformation?.citizenship}</Typography>
                    <Typography fontSize={"small"}><strong>Adres: </strong> {candidateDetail?.personalInformation?.district}, {candidateDetail?.personalInformation?.city} | {candidateDetail?.personalInformation?.address}</Typography>
                    <Typography fontSize={"small"}><strong>Açıklama: </strong> {candidateDetail?.personalInformation?.description}</Typography>
                    <Typography fontSize={"small"}><strong>Sigara Kullanımı: </strong>{candidateDetail?.smoking ? "İçiyor" : "İçmiyor"}</Typography>
                    <Stack spacing={1}>
                        <Typography fontWeight={"bold"}>Eğitim Bilgileri</Typography>
                        <Typography fontSize={"small"}><strong>Lise: </strong> {candidateDetail?.education?.highSchool?.join(', ')}</Typography>
                        <Typography fontSize={"small"}><strong>Yükseköğrenim: </strong> {candidateDetail?.education?.higherEducation?.map((degree: any) => `${degree?.name} - ${degree?.department} - ${getNameOfEnum(HigherEducationDegree, degree?.higherEducationDegree)} - ${degree?.gpa}`)?.join('<br/> ')}</Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography fontWeight={"bold"}>Sertifikalar</Typography>
                        {candidateDetail?.certificates?.map((certificate: any, index: number) => (
                            <Typography key={index} fontSize={"small"}><strong>{certificate?.name}, </strong> {certificate?.type} - {moment(certificate?.issuedDate).format("MM.YYYY")} - {moment(certificate?.validUntil).format("MM.YYYY")} - {certificate?.certificateInstitution}</Typography>
                        ))}
                    </Stack>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack spacing={1} width={{ xs: '100%', sm: "300px" }}>
                    <Stack spacing={1}>
                        <Typography fontWeight={"bold"}>Bildiği Diller</Typography>
                        {candidateDetail?.languages?.map((language: any, index: number) => (
                            <Typography key={index} fontSize={"small"}><strong>{language?.name?.toUpperCase()}:</strong> Okuma: {getNameOfEnum(LanguageLevelEnum, language?.reading)}, Yazma: {getNameOfEnum(LanguageLevelEnum, language?.writing)}, Dinleme: {getNameOfEnum(LanguageLevelEnum, language?.listening)}</Typography>
                        ))}
                    </Stack>

                    <Stack spacing={1}>
                        <Typography fontWeight={"bold"}>Sürücü Belgesi</Typography>
                        <Typography fontSize={"small"}>{getNameOfEnum(DriverLicenseStatusEnum, candidateDetail?.driverLicense?.status)}, {candidateDetail?.driverLicense?.licenseType} sınıfı, {moment(candidateDetail?.driverLicense?.issueDate).format("MM.YYYY")}</Typography>
                    </Stack>

                    <Stack spacing={1}>
                        <Typography fontWeight={"bold"}>Burs ve Burslar</Typography>
                        {candidateDetail?.scholarships?.map((scholarship: any, index: number) => (
                            <Typography key={index} fontSize={"small"}>{moment(scholarship?.receivingDate).format("MM.YYYY")} - {scholarship?.type} - {scholarship?.institution}</Typography>
                        ))}
                    </Stack>
                    <Stack spacing={1}>
                        <Typography fontWeight={"bold"}>Katıldığı Projeler</Typography>
                        {candidateDetail?.participatedProjects?.map((project: any, index: number) => (
                            <Typography key={index} fontSize={"small"}>{project?.name} - {project?.institution} - {project?.level} - {moment(project?.date).format("MM.YYYY")} - {project?.details}</Typography>
                        ))}
                    </Stack>
                    <Stack spacing={1}>
                        <Typography fontWeight={"bold"}>Hobileri</Typography>
                        {candidateDetail?.hobbies?.map((hobby: any, index: number) => (
                            <Typography key={index} fontSize={"small"}>{hobby}</Typography>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => {
            setCandidateDetail(null)
            setCandidateId(null)
        }}
            color="primary" variant='outlined'>
            Kapat
        </Button>
    </DialogActions>
</Dialog> */}

export const OzgelecekCard = ({ ozgelecek, onDelete, onUpdate }: IOzgelecekCardProps) => {


    const [showPdf, setShowPdf] = useState(false);

    const { data, isLoading } = useGetCandidateInformationQuery({})

    const MyDocument = () => {
        return <Document>
            <Page size="A4" style={styles.page}>

                <Image src={Logo} style={{ width: 120, height: 120, objectFit: "contain", position: 'absolute', bottom: 0, right: 40 }} />

                <Text style={styles.title}>Özgelecek Formu</Text>

                <Text style={styles.subtitle}>Kişisel Bilgiler</Text>
                <Text style={styles.text2}>Adı Soyadı: {data?.personalInformation?.name} {data?.personalInformation?.surname}</Text>
                <Text style={styles.text2}>E-posta: {data?.contact?.email} </Text>
                <Text style={styles.text2}>Telefon: {data?.personalInformation?.phoneNumber} </Text>
                <Text style={styles.text2}>Doğum Tarihi: {moment(data?.personalInformation?.birthDate).format('DD MMMM YYYY')} </Text>
                <Text style={styles.text2}>Cinsiyet: {getNameOfEnum(SexEnum, data?.personalInformation?.sex)} </Text>
                <Text style={styles.text2}>Uyruk:  {data?.personalInformation?.nationality} </Text>
                <Text style={styles.text2}>Farklı Vatandaşlık: {data?.personalInformation?.citizenship} </Text>
                <Text style={styles.text2}>Adres: {data?.personalInformation?.district}, {data?.personalInformation?.city} | {data?.personalInformation?.address} </Text>
                <Text style={styles.text2}>Sigara Kullanımı: {data?.smoking ? "İçiyor" : "İçmiyor"} </Text>
                <Text style={styles.text2}>Hakkında: {data?.personalInformation?.description} </Text><br />

                <Text style={styles.subtitle}>Eğitim Bilgileri</Text>
                <Text style={styles.text2}>Lise: {data?.education?.highSchool?.join(', ')} </Text>
                <Text style={styles.text2}>Yükseköğrenim: {data?.education?.higherEducation?.map((degree: any) => `${degree?.name} - ${degree?.department} - ${getNameOfEnum(HigherEducationDegree, degree?.higherEducationDegree)} - ${degree?.gpa}`)?.join(' | ')} </Text><br />

                <Text style={styles.subtitle}>Sertifikalar</Text>
                {data?.certificates?.map((certificate: any, index: number) => (
                    <Text key={index} style={styles.text2}>{certificate?.name}, {certificate?.type} - {moment(certificate?.issuedDate).format("MM.YYYY")} - {moment(certificate?.validUntil).format("MM.YYYY")} - {certificate?.certificateInstitution}</Text>
                ))}<br />

                <Text style={styles.subtitle}>Bildiği Diller</Text>
                {data?.languages?.map((language: any, index: number) => (
                    <Text key={index} style={styles.text2}>{language?.name?.toUpperCase()}: Okuma: {getNameOfEnum(LanguageLevelEnum, language?.reading)}, Yazma: {getNameOfEnum(LanguageLevelEnum, language?.writing)}, Dinleme: {getNameOfEnum(LanguageLevelEnum, language?.listening)}</Text>

                ))}<br />

                <Text style={styles.subtitle}>Sürücü Belgesi</Text>
                <Text style={styles.text2}>{getNameOfEnum(DriverLicenseStatusEnum, data?.driverLicense?.status)}, {data?.driverLicense?.licenseType} sınıfı, {moment(data?.driverLicense?.issueDate).format("MM.YYYY")}</Text><br />


                <Text style={styles.subtitle}>Hobileri</Text>
                {data?.hobbies?.map((hobby: any, index: number) => (
                    <Text key={index} style={styles.text2}>{hobby}</Text>
                ))}<br />


                <Text style={styles.subtitle}>Özgelecek Bilgileri</Text>
                <Text style={styles.text2}>Alan: {upperCasedString(enumFunctions.findAndDisplayName(OzgelecekFieldEnum, ozgelecek?.field))}</Text>
                <Text style={styles.text2}>Alan Testi Sonucu: {ozgelecek?.testResults?.areaTestScore}</Text>
                <Text style={styles.text2}>Motivasyon Testi Sonucu: {ozgelecek?.testResults?.motivationTestScore}</Text>







            </Page>
        </Document>

    }


    const handleDownload = () => {
        setShowPdf(true)
    }

    return (
        <Stack
            direction={{ xs: 'column' }}
            spacing={{ xs: 3, sm: 2 }}
            sx={{
                border: '1px solid #d6ddeb',
                p: 3,
                backgroundColor: '#fff',
                height: '100%',
                position: 'relative',
            }}
        >

            <Stack direction="row" justifyContent="space-between" gap={3} alignItems="start">

                <Box
                    component={"img"}
                    src={LogoCrop}
                    alt="logo"
                    sx={{
                        width: 50,
                        height: 50,
                    }}
                />

                <OzgelecekItemMoreMenu onDelete={onDelete} onUpdate={onUpdate} handleDownload={handleDownload} />
                {showPdf && <Dialog open={true} onClose={() => setShowPdf(false)}>
                    <DialogContent sx={{ width: "600px", height: "900px" }}>
                        <PDFViewer width="100%" height="100%">
                            <MyDocument />
                        </PDFViewer>
                    </DialogContent>
                </Dialog>

                }

            </Stack>

            <Stack direction="column" spacing={1} flex={1}>
                <Typography variant='h5' height={60}>
                    {upperCasedString(enumFunctions.findAndDisplayName(OzgelecekFieldEnum, ozgelecek?.field))}
                </Typography>


                <Stack direction="row" spacing={1} flexWrap="wrap">
                    <Typography variant='body1' flex={1}>
                        Alan Testi Sonucu
                    </Typography>
                    <Divider orientation="vertical" variant="fullWidth" flexItem />
                    <Chip label={ozgelecek?.testResults?.areaTestScore} color="primary" variant="outlined" />
                </Stack>

                <Stack direction="row" spacing={1} flexWrap="wrap">
                    <Typography variant='body1' flex={1}>
                        Motivasyon Testi Sonucu
                    </Typography>
                    <Divider orientation="vertical" variant="fullWidth" flexItem />
                    <Chip label={ozgelecek?.testResults?.areaTestScore} color="success" variant="outlined" />
                </Stack>


                {/* <Stack direction="row" spacing={1} flexWrap="wrap">
                    <Chip label="Full Time" color="primary" />
                    <Divider orientation="vertical" variant="fullWidth" flexItem />

                    <Chip label="Marketing" color="primary" variant="outlined" />
                    <Chip label="Design" color="success" variant="outlined" />
                </Stack> */}
            </Stack>

            <Stack direction="row" spacing={1} flexWrap="wrap">
                <Typography variant='body2' flex={1}>
                    Oluşturulma Tarihi : <span>
                        {moment(ozgelecek.updatedAt).format('DD MMMM YYYY')}
                    </span>
                </Typography>
            </Stack>

        </Stack>
    )
}

const OzgelecekItemMoreMenu = ({ onDelete, onUpdate, handleDownload }: any) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        onDelete()
        handleClose()
    }

    const handleUpdate = () => {
        onUpdate()
        handleClose()
    }


    return (
        <>

            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    // position: 'absolute',
                }}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleDownload()} sx={{ color: 'primary.main' }}>
                    <Download />&nbsp;
                    İndir
                </MenuItem>
                <MenuItem onClick={handleUpdate} sx={{ color: 'success.main' }}>
                    <RefreshIcon />&nbsp;
                    Güncelle
                </MenuItem>
                <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                    <DeleteForeverIcon /> &nbsp;
                    Sil
                </MenuItem>
            </Menu>
        </>
    )
}