import { useState } from 'react'
import * as yup from "yup"
import { Formik } from 'formik'
import { Button, Card, Grid, Typography, FormControlLabel, FormGroup, Checkbox } from '@mui/material'

import { ICertificate } from 'types'
import { FormikCheckbox, FormikDatePicker, FormikTextField } from 'components'




const validationSchema = yup.object().shape({
    name: yup.string().nullable("").required("Sertifika ismi gerekli!"),
    certificateInstitution: yup.string().nullable("").required("Kurum ismi gerekli!"),
    type: yup.string().nullable("").required("Sertifika tipi gerekli!"),
    issuedDate: yup.date().nullable("").required("Başlangıç tarihi gerekli!"),
    validUntil: yup.date().nullable("")
        .when("isChecked", (status, schema) => {

            if (status[0] === true) {
                return schema
            }
            return schema.required("Bitiş tarihi gerekli!")
        }),
    isChecked: yup.boolean(),

})





interface ICertificateFormProps {
    onSubmit: (e: ICertificate) => any,
    initialValues?: ICertificate
}


export const CertificateForm = ({ onSubmit, initialValues }: ICertificateFormProps) => {

    // const [isChecked, setIsChecked] = useState<any>(false)

    const defaultValues = initialValues ?? {
        name: "",
        certificateInstitution: "",
        type: "",
        issuedDate: null,
        validUntil: null,
        isChecked: false,
    }

    return (
        <Formik
            initialValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                console.log({ values });

                onSubmit({
                    name: values?.name,
                    certificateInstitution: values?.certificateInstitution,
                    type: values?.type,
                    issuedDate: values?.issuedDate,
                    validUntil: values?.validUntil,
                })
                resetForm()
            }}
        >
            {
                ({ handleSubmit, setFieldValue, values }) => {

                    return (
                        <Card sx={{ width: '100%', p: 3 }}>
                            <Grid container spacing={3} >

                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Kurs / Sertifika Bilgileri
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikTextField
                                        name="name"
                                        label="Sertifika adı"
                                        upperCase
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikTextField
                                        name="certificateInstitution"
                                        label="Alınan kurum"
                                        upperCase
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikDatePicker
                                        name="issuedDate"
                                        label="Sertifika Tarihi"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikDatePicker
                                        name="validUntil"
                                        label="Geçerlilik Tarihi"
                                        disabled={values.isChecked}
                                        minDate={new Date(values?.issuedDate ?? Date.now())}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikTextField
                                        name="type"
                                        label="Sertifika Türü"
                                        upperCase
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    {/* <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Devam ediyor"
                                            checked={isChecked}
                                            onChange={() => {
                                                setIsChecked(!isChecked)
                                                setFieldValue("validUntil", null)
                                            }}
                                        />
                                    </FormGroup> */}
                                    <FormikCheckbox
                                        name="isChecked"
                                        label="Devam ediyor"
                                        onChange={(e) => {
                                            console.log({ asdfasfasd: values });
                                            setFieldValue("validUntil", null)
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }} >
                                    <Button
                                        onClick={() => handleSubmit()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {initialValues ? "Güncelle" : "Ekle"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    )
                }
            }
        </Formik>
    )
}
