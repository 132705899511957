import * as yup from "yup"
import { useEffect } from "react"
import { Formik, useFormikContext } from 'formik'
import { Button, Card, Grid, Typography } from '@mui/material'

import { useGetCitiesQuery, useGetCountriesQuery } from "context"
import { GendersList, ICandidateInformationInput, ICandidatePersonalInformation, SexsList } from 'types'
import { FormikDatePicker, FormikSelect, FormikSelectCountry, FormikTextField, FormikUpload } from 'components'
import dayjs from "dayjs"




const validationSchema = yup.object().shape({
    personalInformation: yup.object().shape({
        address: yup.string().required(),
        backgroundImageUrl: yup.string().required(),
        birthDate: yup.date().required(),
        city: yup.string().required(),
        country: yup.string().required(),
        description: yup.string().required(),
        district: yup.string().required(),
        name: yup.string().required(),
        nationality: yup.string().required(),
        phoneNumber: yup.string().required(),
        profileImageUrl: yup.string().required(),
        surname: yup.string().required(),
        citizenship: yup.string().required(),

        sex: yup.string().required(),
        gender: yup.string().required(),
        genderDetail: yup.string().required(),
    }),
})


interface ICandidatePersonalInformationFormProps {
    isButton?: boolean,
    onSubmit?: (e: ICandidatePersonalInformation) => any,
    defaultValues?: ICandidatePersonalInformation | undefined | null,
}

export const CandidatePersonalInformationForm = ({ onSubmit, defaultValues, isButton }: ICandidatePersonalInformationFormProps) => {

    const { values, setFieldValue } = useFormikContext<ICandidateInformationInput>()
    const { data: countriesData, } = useGetCountriesQuery({})
    // const { data: parameters } = useGetParametersQuery("")
    const { data: citiesData } = useGetCitiesQuery(
        { countryName: values?.personalInformation?.country?.name ?? values?.personalInformation?.country },
        { skip: !values?.personalInformation?.country }
    )

    useEffect(() => {
        if (values?.personalInformation?.country?.name) {
            setFieldValue("personalInformation.city", null)
        }
    }, [setFieldValue, values?.personalInformation?.country?.name])


    // console.log({ parameters });


    const renderForm = (handleSubmit?: any) => {
        return (
            <Card sx={{ width: '100%', p: 2 }}>

                <Grid container spacing={3} >

                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Personal Information
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField
                            name="personalInformation.name"
                            label="Name"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField
                            name="personalInformation.surname"
                            label="Surname"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField
                            name="personalInformation.phoneNumber"
                            label="Phone Number"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikDatePicker
                            name="personalInformation.birthDate"
                            label="Birth Date"
                            maxDate={dayjs().subtract(18, 'year').toDate()}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <FormikTextField
                            multiline
                            minRows={4}
                            name="personalInformation.description"
                            label="Description"
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <FormikUpload
                            name="personalInformation.backgroundImageUrl"
                            label="Background Image"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikUpload
                            name="personalInformation.profileImageUrl"
                            label="Profile Image"
                        />
                    </Grid>



                    <Grid item xs={12} sm={6}>
                        <FormikSelect
                            name="personalInformation.sex"
                            label="Sex"
                            list={SexsList}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikSelect
                            name="personalInformation.gender"
                            label="Gender"
                            list={GendersList}
                        />
                    </Grid>

                    {
                        defaultValues?.gender === "specified" && (
                            <Grid item xs={12}>
                                <FormikTextField
                                    multiline
                                    minRows={4}
                                    name="personalInformation.genderDetail"
                                    label="Gender Detail"
                                />
                            </Grid>
                        )
                    }

                    <Grid item xs={12} sm={6}>
                        <FormikSelectCountry
                            name="personalInformation.nationality"
                            label="Nationality"
                            list={countriesData?.data ?? []}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <FormikSelectCountry
                            name="personalInformation.country"
                            label="Country"
                            list={countriesData?.data ?? []}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <FormikSelect
                            name="personalInformation.city"
                            label="City"
                            list={citiesData?.data ?? []}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField
                            name="personalInformation.district"
                            label="District"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField
                            name="personalInformation.citizenship"
                            label="Citizenship"
                        />
                    </Grid>


                    <Grid item xs={12} >
                        <FormikTextField
                            multiline
                            minRows={4}
                            name="personalInformation.address"
                            label="Address"
                        />
                    </Grid>





                    {
                        isButton ? (
                            <Grid item xs={12} >
                                <Button
                                    onClick={() => handleSubmit()}
                                    variant="contained"
                                >
                                    Submit
                                </Button>
                            </Grid>
                        ) : (
                            <>
                                {/* <FormSubmitListener values={values} onSubmit={onSubmit} /> */}
                            </>
                        )
                    }
                </Grid>
            </Card>
        )
    }

    if (!isButton) return (<>{renderForm()}</>)

    const initialValues = {
        personalInformation: {
            address: defaultValues?.address ?? null,
            backgroundImageUrl: defaultValues?.backgroundImageUrl ?? null,
            birthDate: defaultValues?.birthDate ?? null,
            city: defaultValues?.city ?? null,
            country: defaultValues?.country ?? null,
            description: defaultValues?.description ?? null,
            district: defaultValues?.district ?? null,
            name: defaultValues?.name ?? null,
            nationality: defaultValues?.nationality ?? null,
            phoneNumber: defaultValues?.phoneNumber ?? null,
            profileImageUrl: defaultValues?.profileImageUrl ?? null,
            surname: defaultValues?.surname ?? null,
            citizenship: defaultValues?.citizenship ?? null,

            gender: defaultValues?.gender ?? null,
            sex: defaultValues?.sex ?? null,
            genderDetail: defaultValues?.genderDetail ?? null,
        },
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onSubmit && onSubmit(values.personalInformation)
                console.log({ values });
            }}
        >
            {
                ({ handleSubmit }) => {
                    return (<>{renderForm(handleSubmit)}</>)
                }
            }
        </Formik>
    )
}
