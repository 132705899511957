import axios from 'axios'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { TOnboardingStatus, TUserType } from 'types'
import { graphqlAxiosConfig, AUTH_SLICE_LOCALSTORAGE_NAME } from 'utils'

interface ILoginDataUser {
    email: string,
    userId: string,
    role: TUserType
}

interface ILoginData {
    user: ILoginDataUser,
    accessToken: string,
    accessExpiresIn: string,
    refreshToken: string,
    refreshExpiresIn: string
}

export interface IAuthState {
    accessToken: string,
    isAuth: boolean,
    isOnboarding: TOnboardingStatus,
    user: null | {
        name: string,
        email: string,
        surname: string,
        profileImageUrl: string,
    },
    userType: TUserType,
    data: ILoginData | null
}

const initialState: IAuthState = {
    accessToken: "",
    isAuth: false,
    isOnboarding: "idle",
    user: null,
    userType: "",
    data: null
}

const localInitialValues: IAuthState = localStorage.getItem(AUTH_SLICE_LOCALSTORAGE_NAME)
    ? JSON.parse(localStorage.getItem(AUTH_SLICE_LOCALSTORAGE_NAME) ?? "")
    : initialState





export const getOnboardingStatus = createAsyncThunk("auth/getOnboardingStatus", async ({ userType }: { userType: TUserType }) => {

    const company = JSON.stringify({
        query: `
        query onboarding_status {
            self_company {
              companyOnboardingStatus
              companyInformation {
                # email
                companyName
                profileImage
              }
              contact {
                email
              }
            }
          }
        `
    });

    const candidate = JSON.stringify({
        query: `
        query onboarding_status {
            self_candidate {
              candidateOnboardingStatus
              contact {
                email
              }
              personalInformation {
                name
                surname
                profileImageUrl
              }

            }
          }
        `
    });

    const data = userType === "company" ? company : candidate


    const response = axios.request(graphqlAxiosConfig({ data, microservice: "user" }))
        .then((res) => {
            const data = res.data.data;

            return {
                onboardingStatus: userType === "company"
                    ? data?.self_company?.companyOnboardingStatus
                    : data?.self_candidate?.candidateOnboardingStatus,
                user: userType === "company"
                    ? data?.self_company
                    : data?.self_candidate
            };
        })
        .catch((err) => {
            return err;
        });

    const result = await response;
    return result;

});



const { actions, reducer } = createSlice({
    name: 'auth',
    initialState: localInitialValues,
    reducers: {
        loginReducer(state, action: PayloadAction<any>) {
            state.isAuth = true
            state.data = action.payload?.data ?? null
            state.accessToken = action.payload?.data?.accessToken ?? ""
            state.userType = action.payload?.data?.user?.role ?? ""
            localStorage.setItem(AUTH_SLICE_LOCALSTORAGE_NAME, JSON.stringify(state))
            document.location.reload()
        },
        logoutReducer(state, action: PayloadAction<any>) {
            state = initialState
            localStorage.clear()
            sessionStorage.clear()
            document.location.reload()
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOnboardingStatus.pending, (state, action) => {
            state.isOnboarding = "idle"
        })
        builder.addCase(getOnboardingStatus.fulfilled, (state, action) => {
            state.isOnboarding = action.payload?.onboardingStatus ?? "error"
            const user = action.payload?.user
            const newUser = {
                name: state.userType === "candidate" ? user?.personalInformation?.name : user?.companyInformation?.companyName,
                email: state.userType === "candidate" ? user?.contact?.email : user?.contact?.email,
                surname: state.userType === "candidate" ? user?.personalInformation?.surname : "",
                profileImageUrl: state.userType === "candidate" ? user?.personalInformation?.profileImageUrl : user?.companyInformation?.profileImage,
            }
            state.user = newUser
            localStorage.setItem(AUTH_SLICE_LOCALSTORAGE_NAME, JSON.stringify(state))
        });
        builder.addCase(getOnboardingStatus.rejected, (state, action) => {
            state.isOnboarding = "error"
        })
    }
})

export const { loginReducer, logoutReducer } = actions
export const authSlice = reducer