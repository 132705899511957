import HasFilter from "interfaces/HasFilter"
import HasPagination from "interfaces/HasPagination"
import IPaginatedListResult from "interfaces/IPaginatedListResult"

// import { CompanyCandidatePoolResponse } from "interfaces/user/ozgelecek/ozgelecek/IOzgelecek"
import { IFilterCompanyCandidatePool } from "interfaces/user/ozgelecek/ozgelecek/IFilterOzgelecek"
import { IOzgelecekForCandidatePool } from "interfaces/user/ozgelecek/ozgelecek/IOzgelecek"

// export interface ICompanyCandidatePoolResponse extends IPaginatedListResult<CompanyCandidatePoolResponse> {}




export interface ICompanyCandidatePoolResponse extends IPaginatedListResult<IOzgelecekForCandidatePool> {}


export interface ICompanyCandidatePoolVariables   extends HasPagination,
HasFilter<IFilterCompanyCandidatePool> {}


export const company_candidatePool = (variables: ICompanyCandidatePoolVariables) => {
  const data = JSON.stringify({
    query: `
      query company_candidatePool($pagination: Pagination, $filter: FilterOzgelecekForCompany) {
        company_candidatePool(pagination: $pagination, filter: $filter) {
          count
          data {
            _id
            candidate {
              _id
             personalInformation {
              name
              surname
              phoneNumber
              sex
              city
              district
            }
              contact {
                phoneNumber
                phoneNumberVerified
                email
                emailVerified
              }
              education {
                highSchool
                higherEducation {
                  higherEducationDegree
                  name
                  department
                  gpa
                  startDate
                  graduationDate
                }
              }
              certificates {
                name
                type
                issuedDate
                validUntil
                certificateInstitution
              }
              driverLicense {
                status
                issueDate
                licenseType
              }
              languages {
                name
                reading
                writing
                listening
              }
              smoking
              scholarships {
                receivingDate
                type
                institution
              }
              participatedProjects {
                name
                institution
                level
                date
                details
              }
              workExperiencesInMonths
              computerKnowledgeLevel
              programmingLanguages {
                name
                level
              }
              hobbies
              candidateOnboardingStatus
              createdAt
              updatedAt
            }
            field
            testResults {
              areaTestScore
              motivationTestScore
              areaTestResults {
                question {
                  _id
                }
                answer
              }
              motivationTestResults {
                answer
              }
            }
            ozgelecekTest {
              first {
                video
                text
              }
              second {
                video
                text
              }
            }
            radarHistory {
              _id
            }
            references {
              _id
            }
            createdAt
            updatedAt
          }
        }
      }
          `,
    variables: variables

  })

  return data

}