import * as yup from "yup"
import { Formik } from 'formik'
import { Button, Card, Grid, Typography } from '@mui/material'

import { DriverLicenseStatusList, IDriverLicense } from 'types'
import { FormikDatePicker, FormikSelect, FormikTextField } from 'components'




const validationSchema = yup.object().shape({
    driverLicense: yup.object().shape({
        issueDate: yup.date().required(),
        licenseType: yup.string().required(),
        status: yup.string().required(),
    }),
})


interface IDriverLicenseFormProps {
    isButton?: boolean,
    onSubmit?: (e: IDriverLicense) => any,
    defaultValues?: IDriverLicense | undefined | null,
}


export const DriverLicenseForm = ({ onSubmit, defaultValues, isButton }: IDriverLicenseFormProps) => {



    const renderForm = (handleSubmit?: any, values?: IDriverLicense | null) => {

        return (
            <Card sx={{ width: '100%', p: 2 }}>

                <Grid container spacing={3} >

                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Driver Licese
                        </Typography>
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <FormikSelect
                            name="driverLicense.status"
                            label="Driver License Status"
                            list={DriverLicenseStatusList}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>

                    </Grid>

                    {
                        values?.status === "exist" && (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <FormikDatePicker
                                        name="driverLicense.issueDate"
                                        label="Issue Date"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikTextField
                                        name="driverLicense.licenseType"
                                        label="License Type"
                                    />
                                </Grid>
                            </>
                        )
                    }

                    {
                        isButton ? (
                            <Grid item xs={12} >
                                <Button
                                    onClick={() => handleSubmit()}
                                    variant="contained"
                                >
                                    Submit
                                </Button>
                            </Grid>
                        ) : (
                            <>
                                {/* <FormSubmitListener values={values} onSubmit={onSubmit} /> */}
                            </>
                        )
                    }
                </Grid>
            </Card>
        )
    }

    if (!isButton) return (<>{renderForm(null, defaultValues)}</>)

    const initialValues = {
        driverLicense: {
            issueDate: defaultValues?.issueDate ?? null,
            licenseType: defaultValues?.licenseType ?? "",
            status: defaultValues?.status ?? ""
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onSubmit && onSubmit(values.driverLicense)
                console.log({ values });
            }}
        >
            {
                ({ handleSubmit, values }) => {
                    return (<>{renderForm(handleSubmit, values.driverLicense)}</>)
                }
            }
        </Formik>
    )
}