import { ComputerKnowledgeLevelEnum, ExtraProductEnums, HigherEducationDegreeEnum, LanguageLevelEnum, OzgelecekFieldEnum, PlaceOfWorkEnums, PositionLevelEnums, ProductTypeEnums, WorkTypeEnums } from "types"




export interface ICompanyApplicationDetailResponse {
  _id: string

  customApplicationLists: Array<{
    _id: string
  }>

  advertisement: {
    _id: string
    title: string
    status: string
    productType: ProductTypeEnums
    extraProducts: ExtraProductEnums
    startDate: string
    quota: number
    quotaVisibleToApplicant: boolean
    field: OzgelecekFieldEnum
    department: string
    language: string
    description: string
    place: PlaceOfWorkEnums
    workType: WorkTypeEnums
    city: string
    district: string
    positionLevel: PositionLevelEnums
    requiredAgeInterval: number
    requiredEducationLevel: HigherEducationDegreeEnum
    requiredSkills: Array<string>
    requiredLanguages: {
      name: string
      reading: string
      writing: string
      listening: string
    }
    militaryServiceRequired: boolean
    requiredDriverLicense: string
    companyInfoVisible: boolean
    images: Array<string>
    replyNotification: string
    customReplyNotification: {
      subject: string
      content: string
    }
    createdBy: {
      _id: string
    }
    createdAt: string
    updatedAt: string
  }
  candidate: {
    _id: string
    personalInformation: {
      name: string
      surname: string
      birthDate: string
      country: string
      city: string
      district: string
      nationality: string
      citizenship: string
      sex: string
      gender: string
      genderDetail: string
      address: string
      phoneNumber: string
      profileImageUrl: string
      backgroundImageUrl: string
      description: string
    }
    contact: {
      phoneNumber: string
      phoneNumberVerified: string
      email: string
      emailVerified: string
    }
    education: {
      highSchool: Array<string>
      higherEducation: {
        higherEducationDegree: HigherEducationDegreeEnum
        name: string
        department: string
        gpa: number
        startDate: string
        graduationDate: string
      }
    }
    certificates: {
      name: string
      type: string
      issuedDate: string
      validUntil: string
      certificateInstitution: string
    }
    driverLicense: {
      status: string
      issueDate: string
      licenseType: string
    }
    languages: {
      name: string
      reading: LanguageLevelEnum
      writing: LanguageLevelEnum
      listening: LanguageLevelEnum
    }
    smoking: boolean
    scholarships: {
      receivingDate: string
      type: string
      institution: string
    }
    participatedProjects: {
      name: string
      institution: string
      level: string
      date: string
      details: string
    }
    workExperiencesInMonths: number
    computerKnowledgeLevel: ComputerKnowledgeLevelEnum
    programmingLanguages: {
      name: string
      level: string
    }
    hobbies: Array<string>
    createdAt: string
    updatedAt: string
  }
  updatedAt: string
  status: string
  ozgelecek: {
    _id: string
    candidate: {
      _id: string
    }
    createdAt: string
    field: string
    ozgelecekTest: {
      first: {
        video: string
        text: string
      }
      second: {
        text: string
        video: string
      }
    }
    references: {
      _id: string
    }
    testResults: {
      motivationTestScore: number
      motivationTestResults: {
        question: {
          _id: string
        }
        answer: string
      }
      areaTestScore: number
      areaTestResults: {
        answer: string
        question: {
          _id: string
        }
      }
    }
    updatedAt: string
  }
  createdAt: string
}


export interface ICompanyApplicationDetailVariables {
  applicationId: string
}




export const company_application = (variables: ICompanyApplicationDetailVariables) => {

  const data = JSON.stringify({
    query: `
    query Company_application($applicationId: String!) {
      company_application(applicationId: $applicationId) {
        _id

        customApplicationLists {
          _id
        }

        advertisement {
          _id
          title
          status
          productType
          extraProducts
          startDate
          quota
          quotaVisibleToApplicant
          field
          department
          language
          description
          place
          workType
          city
          district
          positionLevel
          requiredAgeInterval
          requiredEducationLevel
          requiredSkills
          requiredLanguages {
            name
            reading
            writing
            listening
          }
          militaryServiceRequired
          requiredDriverLicense
          companyInfoVisible
          images
          replyNotification
          customReplyNotification {
            subject
            content
          }
          createdBy {
            _id
          }
          createdAt
          updatedAt
        }
        candidate {
          _id
          personalInformation {
            name
            surname
            birthDate
            country
            city
            district
            nationality
            citizenship
            sex
            gender
            genderDetail
            address
            phoneNumber
            profileImageUrl
            backgroundImageUrl
            description
          }
      #    contact {
      #     phoneNumber
      #     phoneNumberVerified
      #     email
      #     emailVerified
      #   }
          education {
            highSchool
            higherEducation {
              higherEducationDegree
              name
              department
              gpa
              startDate
              graduationDate
            }
          }
          certificates {
            name
            type
            issuedDate
            validUntil
            certificateInstitution
          }
          driverLicense {
            status
            issueDate
            licenseType
          }
          languages {
            name
            reading
            writing
            listening
          }
          smoking
          scholarships {
            receivingDate
            type
            institution
          }
          participatedProjects {
            name
            institution
            level
            date
            details
          }
          workExperiencesInMonths
          computerKnowledgeLevel
          programmingLanguages {
            name
            level
          }
          hobbies
          createdAt
          updatedAt
        }
        updatedAt
        status
        ozgelecek {
          _id
          candidate {
            _id
          }
          createdAt
          field
          ozgelecekTest {
            first {
              video
              text
            }
            second {
              text
              video
            }
          }
          references {
            _id
          }
          testResults {
            motivationTestScore
            motivationTestResults {
              question {
                _id
              }
              answer
            }
            areaTestScore
            areaTestResults {
              answer
              question {
                _id
              }
            }
          }
          updatedAt
        }
        createdAt
      }
    }
        `,
    variables: {
      "applicationId": variables?.applicationId ?? null
    }

  })


  return data

}