import { ExtraProductEnums, ProductTypeEnums } from "types"




export interface ICompanyGetAdvertisementCostResponse {
  "cost": {
    "mandatoryStandardCredit": number
  },
  "discount": number,
  "netPrice": number,
  "price": number
}


export interface ICompanyGetAdvertisementCostVariables {
  productType?: any,
  extraProductTypes?: Array<ExtraProductEnums>  | string[] | null,
  onlyForExtraProduct: boolean
}




export const company_getAdvertisementCost = (variables: ICompanyGetAdvertisementCostVariables) => {

  const data = JSON.stringify({
    query: `query Company_getAdvertisementCost($input: GetAdvertisementPriceInput) {
      company_getAdvertisementCost(input: $input) {
        cost {
          mandatoryStandardCredit
        }
        discount
        netPrice
        price
      }
    }`,
    variables: {
      "input": {
        "extraProductTypes": variables?.extraProductTypes,
        "onlyForExtraProduct": variables?.onlyForExtraProduct,
        "productType": variables?.productType,
      }
    }

  })


  return data

}