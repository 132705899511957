import { useState } from 'react'
import * as yup from "yup"
import { Formik } from 'formik'
import { Button, Card, Checkbox, Grid, Typography, FormControlLabel, FormGroup } from '@mui/material'

import { HigherEducationDegreesList, IHigherEducation } from 'types'
import { FormikCheckbox, FormikDatePicker, FormikSelect, FormikTextField } from 'components'


const validationSchema = yup.object().shape({
    name: yup.string().required("Üniversite ismi zorunlu!"),
    department: yup.string().required("Departman ismi zorunlu!"),
    higherEducationDegree: yup.string().required("Gpa zorunlu!"),
    startDate: yup.date().required("Başlama tarihi zorunlu!"),
    graduationDate: yup.date().nullable("")
        .when("isChecked", (status, schema) => {

            if (status[0] === true) {
                return schema
            }
            return schema.nullable("").required("Bitiş tarihi zorunludur")
        }),
    isChecked: yup.boolean(),

    gpa: yup.number().min(0, "Sıfırın altında olamaz!").max(4, "4'ten fazla olamaz").required("Mezuniyet derecesi zorunludur")
})


interface IHigherEducationFormFormProps {
    onSubmit?: (e: IHigherEducation) => any,
    initialValues?: IHigherEducation | undefined | null,
}


export const HigherEducationForm = ({ onSubmit, initialValues }: IHigherEducationFormFormProps) => {

    // const [isChecked, setIsChecked] = useState<any>(false)

    const defaultValues: IHigherEducation = initialValues ?? {
        department: "",
        higherEducationDegree: "",
        name: "",
        startDate: null,
        graduationDate: null,
        gpa: "",
        isChecked: false,
    }


    return (
        <Formik
            initialValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                onSubmit && onSubmit({
                    department: values?.department,
                    higherEducationDegree: values?.higherEducationDegree,
                    name: values?.name,
                    startDate: values?.startDate ?? null,
                    graduationDate: values?.graduationDate ?? null,
                    gpa: values?.gpa,
                })
                resetForm()
            }}
        >
            {
                ({ handleSubmit, setFieldValue, values }) => {

                    return (
                        <Card sx={{ width: '100%', p: 3 }}>

                            <Grid container spacing={3} >

                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Üniversite Bilgileri
                                    </Typography>
                                </Grid>


                                <Grid item xs={12} sm={6}>
                                    <FormikTextField
                                        name="name"
                                        label="Üniversite adı"
                                        upperCase
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikTextField
                                        name="department"
                                        label="Üniversite bölümü"
                                        upperCase
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikSelect
                                        name="higherEducationDegree"
                                        label="Üniversite türü"
                                        list={HigherEducationDegreesList}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikTextField
                                        type="number"
                                        name="gpa"
                                        label="Mezuniyet Derecesi (GPA)"

                                    />
                                </Grid>


                                <Grid item xs={12} sm={6}>
                                    <FormikDatePicker
                                        name="startDate"
                                        label="Başlangıç Tarihi"
                                        maxDate={new Date(Date.now())}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={6}>
                                    <FormikDatePicker
                                        name="graduationDate"
                                        label="Mezuniyet Tarihi"
                                        minDate={new Date(values?.startDate ?? "")}
                                        disabled={!!values?.isChecked}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    {/* <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Devam ediyor"
                                            checked={isChecked}
                                            onChange={() => {
                                                setFieldValue("graduationDate", null)
                                                setIsChecked(!isChecked)
                                            }}
                                        />
                                    </FormGroup> */}

                                    <FormikCheckbox
                                        name="isChecked"
                                        label="Devam ediyor"
                                        onChange={(e) => {
                                            setFieldValue("graduationDate", null)
                                        }}
                                    />
                                </Grid>




                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }} >
                                    <Button
                                        onClick={() => handleSubmit()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {initialValues ? "Güncelle" : "Ekle"}
                                    </Button>
                                </Grid>

                            </Grid>
                        </Card>
                    )
                }
            }
        </Formik>
    )
}