import * as yup from "yup"
import { Formik } from 'formik'
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'

import { IProgrammingLanguage, ProgrammingLanguageLevelsList } from 'types'
import { FormikSelect, FormikTextField } from 'components'
import { AnimatePresence, motion } from "framer-motion"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';




const validationSchema = yup.object().shape({
    name: yup.string().nullable()?.required("Gerekli!"),
    level: yup.string().nullable()?.required("Gerekli!")
})





interface IProgrammingLanguageFormProps {
    onSubmit: (e: IProgrammingLanguage) => void,
    onDeleteItem: (e?: any) => void,
    // initialValues?: IProgrammingLanguage
    list: Array<any>
}


export const ProgrammingLanguageForm = ({ onSubmit, onDeleteItem, list }: IProgrammingLanguageFormProps) => {

    const defaultValues = {
        name: "",
        level: "",
    }

    return (
        <Stack>


            <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    onSubmit(values)
                    resetForm()
                }}
            >
                {
                    ({ handleSubmit }) => {
                        return (
                            <Grid container spacing={1} >

                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Kullandığınız Programlar
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={5}>
                                    <FormikTextField
                                        name="name"
                                        label="Program Adı"
                                        upperCase
                                    />
                                </Grid>

                                <Grid item xs={12} sm={5}>
                                    <FormikSelect
                                        name="level"
                                        label="Program Seviyesi"
                                        list={ProgrammingLanguageLevelsList}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={2}>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Ekle
                                    </Button>
                                </Grid>

                            </Grid>
                        )
                    }
                }
            </Formik>


            <Stack direction="row" gap={2} sx={{ mt: 3 }} flexWrap="wrap">
                <AnimatePresence mode="popLayout" >
                    {
                        list.map((language, index) => {

                            return (
                                <motion.div

                                    layout
                                    initial={{ scale: 0.8, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    exit={{ scale: 0.8, opacity: 0 }}
                                    transition={{ type: "spring" }}
                                    key={language.name}
                                    style={{ position: 'relative' }}
                                >

                                    <Button variant="contained" color="secondary" >
                                        {language.name} ({language.level})
                                    </Button>

                                    <Box
                                        onClick={() => onDeleteItem(index)}
                                        sx={{
                                            bgcolor: 'primary.main',
                                            position: 'absolute',
                                            top: "-8px",
                                            right: "-8px",
                                            borderRadius: '100%',

                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',

                                            '&:hover': {
                                                transform: 'scale(1.05)'
                                            },
                                            '&:active': {
                                                transform: 'scale(1)'
                                            },
                                        }}
                                    >
                                        <CloseRoundedIcon sx={{ width: '20px', height: '20px', color: '#fff' }} />
                                    </Box>

                                </motion.div>

                            )
                        })
                    }

                </AnimatePresence>
            </Stack>

        </Stack>
    )
}