

import { userApi } from "../userApi";
import {
    ICompanyAddCustomApplicationListItemsResponse,
    ICompanyAddCustomApplicationListItemsVariables,
    ICompanyCreateCustomApplicationListResponse,
    ICompanyCreateCustomApplicationListVariables,
    ICompanyMakeApplicationBlackListedResponse,
    ICompanyMakeApplicationBlackListedVariables,
    ICompanyMakeApplicationSuitableResponse,
    ICompanyMakeApplicationSuitableVariables,
    ICompanyMakeApplicationUnsuitableResponse,
    ICompanyMakeApplicationUnsuitableVariables,
    ICompanyRemoveCustomApplicationListItemsResponse,
    ICompanyRemoveCustomApplicationListItemsVariables,
    ICompanyRemoveCustomApplicationListResponse,
    ICompanyRemoveCustomApplicationListVariables,
    ICompanyViewApplicationResponse,
    ICompanyViewApplicationVariables,
    ICompanyCreateAdvertisementResponse,
    ICompanyCreateAdvertisementVariables,
    ICompanyUpdateAdvertisementVariables,
    ICompanyUpdateAdvertisementResponse,
    ICompanyRequestApprovalResponse,
    ICompanyRequestApprovalVariables,
    ICompanyCompleteAdvertisementOnboardingResponse,
    ICompanyCompleteAdvertisementOnboardingVariables,
    ICompanyAddExtraProductyToAdvertisementResponse,
    ICompanyAddExtraProductyToAdvertisementVariables,

    company_addCustomApplicationListItems,
    company_createAdvertisement,
    company_createCustomApplicationList,
    company_makeApplicationBlackListed,
    company_makeApplicationSuitable,
    company_makeApplicationUnsuitable,
    company_removeCustomApplicationList,
    company_removeCustomApplicationListItems,
    company_updateAdvertisement,
    company_viewApplication,
    company_requestApproval,
    company_completeAdvertisementOnboarding,
    company_addExtraProductToAdvertisement
} from "./mutations";

import {
    ICompanyAdvertisementResponse,
    ICompanyAdvertisementVariables,
    ICompanyAdvertisementsResponse,
    ICompanyAdvertisementsVariables,

    ICompanyApplicationDetailResponse,
    ICompanyApplicationDetailVariables,

    ICompanyApplicationsByCustomListIdVariables,

    ICompanyApplicationsResponse,
    ICompanyApplicationsVariables,

    ICompanyCandidatePoolPriceResponse,

    ICompanyCandidatePoolPriceVariables,

    ICompanyCandidatePoolResponse,

    ICompanyCandidatePoolVariables,

    ICompanyCandidateResponse,

    ICompanyCandidateVariables,

    ICompanyMatchingOzgelecekCountResponse,

    ICompanyMatchingOzgelecekCountVariables,

    ICompanyMatchingOzgelecekPriceResponse,

    ICompanyMatchingOzgelecekPriceVariables,

    ICompanyMatchingOzgeleceksResponse,

    ICompanyMatchingOzgeleceksVariables,

    ICompanyRadarHistoriesResponse,

    // ICompanyRadarHistoriesVariables,

    ICompanyRadarHistoryResponse,

    ICompanyRadarHistoryVariables,

    ISelfCompanyResponse,
    ISelfCompanyVariables,

    ICompanyGetAdvertisementCostVariables,
    ICompanyGetAdvertisementCostResponse,
    ICompanyApplicationStatisticsResponse,



    company_advertisement,
    company_advertisements,
    company_application,
    company_applications,
    company_candidate,
    company_candidateFromPoolPrice,
    company_candidatePool,
    company_customApplicationListById,
    company_customApplicationLists,
    company_matchingOzgelecekCount,
    company_matchingOzgelecekPrice,
    company_matchingOzgeleceks,
    company_radarHistories,
    company_radarHistory,
    self_company,
    company_getAdvertisementCost,
    company_applicationStatistics,

} from "./queries";
import { ICompanyOzgeleceksResponse, ICompanyOzgeleceksVariables, company_ozgeleceks } from "./queries/_company_ozgeleceks";
import { IGraphqlResponse } from "interfaces/IGraphqlResponse";


const companyUserApi = userApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        getCompanyInformation: builder.query<ISelfCompanyResponse, ISelfCompanyVariables>({
            query: (variables: ISelfCompanyVariables) => {
                return ({
                    url: "",
                    body: self_company(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.self_company
            }

        }),

        getCompanyAdvertisements: builder.query<ICompanyAdvertisementsResponse, ICompanyAdvertisementsVariables>({
            query: (variables: ICompanyAdvertisementsVariables) => {
                return ({
                    url: "",
                    body: company_advertisements(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_advertisements
            },
            providesTags: ["company_advertisements"]
        }),

        getCompanyAdvertisementDetail: builder.query<ICompanyAdvertisementResponse, ICompanyAdvertisementVariables>({
            query: (variables: ICompanyAdvertisementVariables) => {
                // console.log({ variables });
                return ({
                    url: "",
                    body: company_advertisement(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_advertisement
            },
            providesTags: ["company_advertisement"]
        }),

        getCompanyApplications: builder.query<ICompanyApplicationsResponse, ICompanyApplicationsVariables>({
            query: (variables: ICompanyApplicationsVariables) => {
                return ({
                    url: "",
                    body: company_applications(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_applications
            },
            providesTags: ["company_applications"]
        }),

        getCompanyApplicationDetails: builder.query<ICompanyApplicationDetailResponse, ICompanyApplicationDetailVariables>({
            query: (variables: ICompanyApplicationDetailVariables) => {
                return ({
                    url: "",
                    body: company_application(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_application
            },
            providesTags: ["company_application"]
        }),

        getCompanyCustomApplicationLists: builder.query<any, any>({
            query: () => {
                // console.log({ variables });
                return ({
                    url: "",
                    body: company_customApplicationLists()
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_customApplicationLists
            },
            providesTags: ["company_custom_application_list"]
        }),

        getCompanypplicationsByCustomListId: builder.query<any, ICompanyApplicationsByCustomListIdVariables>({
            query: (variables: ICompanyApplicationsByCustomListIdVariables) => {
                // console.log({ variables });
                return ({
                    url: "",
                    body: company_customApplicationListById(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_customApplicationLists
            },
            // providesTags: [""]
        }),

        getAdvertisementCost: builder.query<ICompanyGetAdvertisementCostResponse, ICompanyGetAdvertisementCostVariables>({
            query: (variables: ICompanyGetAdvertisementCostVariables) => {
                return ({
                    url: "",
                    body: company_getAdvertisementCost(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_getAdvertisementCost
            },
            providesTags: ["get_advertisement_cost"]
        }),



        getCompanyCandidate: builder.query<IGraphqlResponse<ICompanyCandidateResponse>, ICompanyCandidateVariables>({
            query: (variables: ICompanyCandidateVariables) => {
                return ({
                    url: "",
                    body: company_candidate(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            // providesTags: ["company_candidate"],
        }),
        getCompanyCandidatePool: builder.query<ICompanyCandidatePoolResponse, ICompanyCandidatePoolVariables>({
            query: (variables: ICompanyCandidatePoolVariables) => {
                return ({
                    url: "",
                    body: company_candidatePool(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_candidatePool
            },
            // providesTags: ["company_candidatePool"]
        }),
        getCompanyCandidatePoolPrice: builder.query<ICompanyCandidatePoolPriceResponse, ICompanyCandidatePoolPriceVariables>({
            query: (variables: ICompanyCandidatePoolPriceVariables) => {
                return ({
                    url: "",
                    body: company_candidateFromPoolPrice(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_candidateFromPoolPrice
            },
            providesTags: ["company_candidatePoolPrice"]
        }),

        getCompanyMatchingOzgelecekCount: builder.query<ICompanyMatchingOzgelecekCountResponse, ICompanyMatchingOzgelecekCountVariables>({
            query: (variables: ICompanyMatchingOzgelecekCountVariables) => {
                return ({
                    url: "",
                    body: company_matchingOzgelecekCount(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_matchingOzgelecekCount
            },
            providesTags: ["company_matchingOzgelecekCount"]
        }),
        getCompanyMatchingOzgelecekPrice: builder.query<any, ICompanyMatchingOzgelecekPriceVariables>({
            query: (variables: ICompanyMatchingOzgelecekPriceVariables) => {
                return ({
                    url: "",
                    body: company_matchingOzgelecekPrice(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_matchingSystemUsagePrice
            },
            // providesTags: ["company_candidatePoolPrice"]
        }),
        getCompanyMatchingOzgeleceks: builder.query<ICompanyMatchingOzgeleceksResponse, ICompanyMatchingOzgeleceksVariables>({
            query: (variables: ICompanyMatchingOzgeleceksVariables) => {
                return ({
                    url: "",
                    body: company_matchingOzgeleceks(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_matchingOzgeleceks
            },
            // providesTags: ["company_candidatePoolPrice"]
        }),
        getCompanyOzgeleceks: builder.query<ICompanyOzgeleceksResponse, ICompanyOzgeleceksVariables>({
            query: (variables: ICompanyOzgeleceksVariables) => {
                return ({
                    url: "",
                    body: company_ozgeleceks(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_ozgeleceks
            },
            providesTags: ["company_ozgeleceks"]
        }),


        getCompanyRadarHistory: builder.query<ICompanyRadarHistoryResponse, ICompanyRadarHistoryVariables>({
            query: (variables: ICompanyRadarHistoryVariables) => {
                return ({
                    url: "",
                    body: company_radarHistory(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_radarHistory
            },
            providesTags: ["company_radarHistory"]
        }),
        getCompanyRadarHistories: builder.query<ICompanyRadarHistoriesResponse, ICompanyCandidatePoolVariables>({
            query: (variables: ICompanyCandidatePoolVariables) => {
                return ({
                    url: "",
                    body: company_radarHistories(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_radarHistories
            },
            // providesTags: ["company_radarHistories"]
        }),
        getCompanyApplicationStatistics: builder.query<ICompanyApplicationStatisticsResponse, any>({
            query: () => {
                return ({
                    url: "",
                    body: company_applicationStatistics()
                })
            },
            transformResponse: (response: any) => {
                return response.data.company_applicationStatistics
            },
            // providesTags: ["company_radarHistories"]
        }),




        // #################### User Mutations #################
        companyCreateAdvertisement: builder.mutation<ICompanyCreateAdvertisementResponse, ICompanyCreateAdvertisementVariables>({
            query: (variables: ICompanyCreateAdvertisementVariables) => {
                return ({
                    url: "",
                    body: company_createAdvertisement(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["company_advertisement", "company_advertisements"]
        }),

        companyUpdateAdvertisement: builder.mutation<ICompanyUpdateAdvertisementResponse, ICompanyUpdateAdvertisementVariables>({
            query: (variables: ICompanyUpdateAdvertisementVariables) => {
                return ({
                    url: "",
                    body: company_updateAdvertisement(variables)
                })
            },
            transformResponse: (response: any) => {
                console.log(response);
                return response;
            },
            invalidatesTags: ["company_advertisement", "company_advertisements", "candidate_advertisements", "get_advertisement_cost"]
        }),






        companyCreateCustomApplicationList: builder.mutation<ICompanyCreateCustomApplicationListResponse, ICompanyCreateCustomApplicationListVariables>({
            query: (variables: ICompanyCreateCustomApplicationListVariables) => {
                return ({
                    url: "",
                    body: company_createCustomApplicationList(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["company_custom_application_list"]
        }),

        companyRemoveCustomApplicationList: builder.mutation<ICompanyRemoveCustomApplicationListResponse, ICompanyRemoveCustomApplicationListVariables>({
            query: (variables: ICompanyRemoveCustomApplicationListVariables) => {
                return ({
                    url: "",
                    body: company_removeCustomApplicationList(variables)
                })
            },
            transformResponse: (response: any) => {
                return response?.data?.company_removeCustomApplicationList
            },
            invalidatesTags: ["company_custom_application_list"]
        }),




        companyAddCustomApplicationListItems: builder.mutation<ICompanyAddCustomApplicationListItemsResponse, ICompanyAddCustomApplicationListItemsVariables>({
            query: (variables: ICompanyAddCustomApplicationListItemsVariables) => {
                return ({
                    url: "",
                    body: company_addCustomApplicationListItems(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["company_application", "company_applications"]
        }),

        companyRemoveCustomApplicationListItems: builder.mutation<ICompanyRemoveCustomApplicationListItemsResponse, ICompanyRemoveCustomApplicationListItemsVariables>({
            query: (variables: ICompanyRemoveCustomApplicationListItemsVariables) => {
                return ({
                    url: "",
                    body: company_removeCustomApplicationListItems(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["company_application", "company_applications"]
        }),




        companyViewApplication: builder.mutation<ICompanyViewApplicationResponse, ICompanyViewApplicationVariables>({
            query: (variables: ICompanyViewApplicationVariables) => {
                return ({
                    url: "",
                    body: company_viewApplication(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["candidate_application", "company_applications"]
        }),

        companyMakeApplicationBlackListed: builder.mutation<ICompanyMakeApplicationBlackListedResponse, ICompanyMakeApplicationBlackListedVariables>({
            query: (variables: ICompanyMakeApplicationBlackListedVariables) => {
                return ({
                    url: "",
                    body: company_makeApplicationBlackListed(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["candidate_application", "company_applications"]
        }),

        companyMakeApplicationSuitable: builder.mutation<ICompanyMakeApplicationSuitableResponse, ICompanyMakeApplicationSuitableVariables>({
            query: (variables: ICompanyMakeApplicationSuitableVariables) => {
                return ({
                    url: "",
                    body: company_makeApplicationSuitable(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["candidate_application", "company_applications"]
        }),

        companyMakeApplicationUnsuitable: builder.mutation<ICompanyMakeApplicationUnsuitableResponse, ICompanyMakeApplicationUnsuitableVariables>({
            query: (variables: ICompanyMakeApplicationUnsuitableVariables) => {
                return ({
                    url: "",
                    body: company_makeApplicationUnsuitable(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["candidate_application", "company_applications"]
        }),

        companyRequestApproval: builder.mutation<ICompanyRequestApprovalResponse, ICompanyRequestApprovalVariables>({
            query: (variables: ICompanyRequestApprovalVariables) => {
                return ({
                    url: "",
                    body: company_requestApproval(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            // invalidatesTags: [""]
        }),

        companyCompleteAdvertisementOnboarding: builder.mutation<ICompanyCompleteAdvertisementOnboardingResponse, ICompanyCompleteAdvertisementOnboardingVariables>({
            query: (variables: ICompanyCompleteAdvertisementOnboardingVariables) => {
                return ({
                    url: "",
                    body: company_completeAdvertisementOnboarding(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["company_advertisement", "company_advertisements"]
        }),

        companyAddExtraProductToAdvertisement: builder.mutation<ICompanyAddExtraProductyToAdvertisementResponse, ICompanyAddExtraProductyToAdvertisementVariables>({
            query: (variables: ICompanyAddExtraProductyToAdvertisementVariables) => {
                return ({
                    url: "",
                    body: company_addExtraProductToAdvertisement(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["company_advertisement", "company_advertisements"]
        }),



    }),
})


export const {
    // ########## queries ###############
    useGetCompanyInformationQuery,
    useLazyGetCompanyInformationQuery,

    useGetCompanyAdvertisementsQuery,
    useLazyGetCompanyAdvertisementsQuery,

    useGetCompanyAdvertisementDetailQuery,
    useLazyGetCompanyAdvertisementDetailQuery,

    useGetCompanyCustomApplicationListsQuery,
    useLazyGetCompanyCustomApplicationListsQuery,

    useGetCompanypplicationsByCustomListIdQuery,
    useLazyGetCompanypplicationsByCustomListIdQuery,
    // useGetCompanyCustomApplicationListDetailQuery,
    // useLazyGetCompanyCustomApplicationListDetailQuery,

    useGetCompanyApplicationsQuery,
    useLazyGetCompanyApplicationsQuery,

    useGetCompanyApplicationDetailsQuery,
    useLazyGetCompanyApplicationDetailsQuery,

    useGetAdvertisementCostQuery,
    useLazyGetAdvertisementCostQuery,

    useGetCompanyApplicationStatisticsQuery,
    useLazyGetCompanyApplicationStatisticsQuery,

    // Radar System
    useGetCompanyCandidatePoolPriceQuery,
    useLazyGetCompanyCandidatePoolPriceQuery,
    useGetCompanyCandidatePoolQuery,
    useLazyGetCompanyCandidatePoolQuery,
    useGetCompanyCandidateQuery,
    useLazyGetCompanyCandidateQuery,
    useGetCompanyMatchingOzgelecekCountQuery,
    useLazyGetCompanyMatchingOzgelecekCountQuery,
    useGetCompanyMatchingOzgelecekPriceQuery,
    useLazyGetCompanyMatchingOzgelecekPriceQuery,
    useGetCompanyMatchingOzgeleceksQuery,
    useLazyGetCompanyMatchingOzgeleceksQuery,
    useGetCompanyRadarHistoriesQuery,
    useLazyGetCompanyRadarHistoriesQuery,
    useGetCompanyRadarHistoryQuery,
    useLazyGetCompanyRadarHistoryQuery,
    useGetCompanyOzgeleceksQuery,
    useLazyGetCompanyOzgeleceksQuery,


    // ########## mutation ###############
    useCompanyCreateAdvertisementMutation,
    useCompanyUpdateAdvertisementMutation,

    useCompanyCreateCustomApplicationListMutation,
    useCompanyRemoveCustomApplicationListMutation,

    useCompanyMakeApplicationBlackListedMutation,
    useCompanyMakeApplicationSuitableMutation,
    useCompanyMakeApplicationUnsuitableMutation,
    useCompanyViewApplicationMutation,
    useCompanyAddCustomApplicationListItemsMutation,

    useCompanyRemoveCustomApplicationListItemsMutation,

    useCompanyRequestApprovalMutation,
    useCompanyCompleteAdvertisementOnboardingMutation,

    useCompanyAddExtraProductToAdvertisementMutation,

} = companyUserApi