import { enumFunctions } from "utils"

export type TUserType = "company" | "candidate" | string

export type TOnboardingStatus = "idle" | "error" | "completed" | "notCompleted" | "pendingRequest" | "pendingApproval" | "approved" | "rejected" | "pendingOnboarding"

export type TMicroService = "user"


export enum GenderEnum {
    specified = "Belirtmek istiyorum",
    notSpecified = "Belirtmek istemiyorum",
}

export enum SexEnum {
    male = "Erkek",
    female = "Kadın",
    other = "Diğer"
}

export enum ProgrammingLanguageLevelEnum {
    beginner = "Temel",
    intermediate = "Orta",
    advance = "İleri",
}

export enum DriverLicenseStatusEnum {
    exist = "Var",
    notExist = "Yok"
}

export enum LanguageLevelEnum {
    beginner = "Temel",
    intermediate = "Orta",
    advance = "İleri",
    native = "Ana Dil",
}

export enum ComputerKnowledgeLevelEnum {
    beginner = "Temel",
    intermediate = "Orta",
    advance = "İleri",
    none = "Yok",
}

export enum NumberOfEmployeeEnum {
    upTo10 = "10'dan Az",
    between10_50 = "10-50 Arası",
    between51_100 = "51-100 Arası",
    between101_250 = "101-250 Arası",
    between251_999 = "251-999 Arası",
    greaterThan1000 = "1000'den Fazla",
}

export enum HigherEducationDegreeEnum {
    associate = "Ön Lisans",
    bachelor = "Lisans",
    master = "Yüksek Lisans",
    doctoral = "Doktora",
}

export enum OzgelecekFieldEnum {
    arge = "Arge",
    satisVePazarlama = "Satış ve Pazarlama",
    satinAlma = "Satın Alma",
    bilgiTeknolojileriVeIT = "Bilgi Teknolojileri ve IT",
    yeniMedyaVeDigitalPazarlama = "Yeni Medya ve Dijital Pazarlama",
    muhasebeVeFinans = "Muhasebe ve Finans",
    egitim = "Eğitim",
    muhendislik = "Muhendislik",
    danisma = "Danışma",
    idariIsler = "İdari İşler",
    insanKaynaklari = "İnsan Kaynakları",
    ithalatVeIhracat = "İthalat ve İhracat",
    turizm = "Turizm",
    gayrimenkul = "Gayrimenkul",
    logistik = "Lojistik",
    sigortacilik = "Sigortacılık",
    uretimVeImalat = "Üretim ve İmalat",
    magazacilik = "Mağazacilik",
    cafeVeRestorant = "Kafe ve Restoran",
    cagriMerkezi = "Çagrı Merkezi",
}

export enum CandidateReferenceStatusEnums {
    pending = "Onay Bekliyor",
    informationApproved = "Doğrulandı",
    informationRejected = "Reddedildi",
    referenceApproved = "Kabul Edildi",
    referenceRejected = "Reddedildi",
}

// export enum LanguageEnums {
//     tr = "Türkçe",
//     en = "İngilizce",
//     fr = "Fransızca",
//     de = "Almanca",
//     it = "İtalyanca",
//     pt = "Portekizce",
//     ru = "Rusça",
//     es = "İspanyolca",
// }

export const SexsList = enumFunctions.objectlist(SexEnum)
export const GendersList = enumFunctions.objectlist(GenderEnum)
export const languageLevelsList = enumFunctions.objectlist(LanguageLevelEnum)
export const DriverLicenseStatusList = enumFunctions.objectlist(DriverLicenseStatusEnum)
export const ProgrammingLanguageLevelsList = enumFunctions.objectlist(ProgrammingLanguageLevelEnum)
export const ComputerKnowledgeLevelsList = enumFunctions.objectlist(ComputerKnowledgeLevelEnum)
export const NumberOfEmployeesList = enumFunctions.objectlist(NumberOfEmployeeEnum)
export const HigherEducationDegreesList = enumFunctions.objectlist(HigherEducationDegreeEnum)
export const OzgelecekFieldsList = enumFunctions.objectlist(OzgelecekFieldEnum)




export const getNameOfEnum = (enumObject: any, name: string): string | undefined => {
    return enumObject[name];
};


export const getValueOfEnum = (enumObject: any, value: string): string | undefined => {
    const enumKeys = Object.keys(enumObject);
    for (const key of enumKeys) {
        if (enumObject[key] === value) {
            return key;
        }
    }
    return undefined;
};

