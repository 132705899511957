
import { createRoot } from 'react-dom/client';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Wrapper from './Wrapper';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './resources';

import 'moment/locale/tr'


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    }
  });

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<Wrapper />);
