import { ICompanyAdvertisementsItem } from "./_company_advertisements"


export interface ICompanyAdvertisementResponse extends ICompanyAdvertisementsItem {

}
export interface ICompanyAdvertisementVariables {
  advertisementId?: string
}


export const company_advertisement = (variables: ICompanyAdvertisementVariables) => {

  if (!variables?.advertisementId) return null

  const data = JSON.stringify({
    query: `
      query Company_advertisement($advertisementId: ID!) {
        company_advertisement(advertisementId: $advertisementId) {
          _id
          title
          status
          productType
          extraProducts
          startDate
          quota
          quotaVisibleToApplicant
          field
          department
          language
          description
          place
          workType
          city
          district
          positionLevel
          requiredAgeInterval
          requiredEducationLevel
          requiredSkills
          requiredLanguages {
            name
            reading
            writing
            listening
          }
          militaryServiceRequired
          requiredDriverLicense
          companyInfoVisible
          images
          replyNotification
          customReplyNotification {
            subject
            content
          }
          createdAt
          updatedAt
        }
      }
          `,
    variables: {
      "advertisementId": variables?.advertisementId
    }

  })

  return data

}