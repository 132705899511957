export const reference_referenceQuestions = (variables: any) => {

    const data = JSON.stringify({
        query: `
        query Reference_referenceQuestions($token: String!, $filter: FilterReferenceQuestionInputForUser, $pagination: PaginationWithReferenceQuestionSort) {
            reference_referenceQuestions(token: $token, filter: $filter, pagination: $pagination) {
              count
              data {
                updatedAt
                title
                # isReverseTest
                index
                createdAt
                content
                _id
              }
            }
          }
        `,
        variables: {
            "token": variables?.token ?? null,

            "filter": {
                "index": variables?.filter?.index ?? null,
            },
            "pagination": {
                "page": variables?.pagination?.page ?? null,
                "pageSize": variables?.pagination?.pageSize ?? null,
                "sort": variables?.pagination?.sort ?? null,
            }
        }
    })

    return data
}
